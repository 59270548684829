import React from 'react';
import { useTranslation } from 'react-i18next';
import './Terms.css';
import { Link } from "react-router-dom";
import NavBar from "./NavBar"; // Import NavBar component
 // Import Footer component
import Footer from './Footer';

const Terms = () => {
  const { t } = useTranslation();

  return (
    <div className='landing-page terms'>
      {/* Navbar for Terms Page */}
      <NavBar />

      {/* Content of Terms Page */}
      <div className='landinginfo'>    
        <div className='section'>
          <h1>{t('termsAndConditions')}</h1>
          <h2 className="sectionTitle">{t('appUsageRules1')}</h2>
          <p className="paragraph">
            <span className="bold">{t('sourcesOfProductInformation')}</span> {t('appUsesDataFrom')}{' '}
            <Link to="https://world.openfoodfacts.org/" className="link">{t('openFoodFactsAPI')}</Link>. {t('andAlsoUsesDataFromOwnDatabase')}
          </p>
          <p className="paragraph">{t('dataUpdate')}</p>
          <p className="sectionDetails">{t('dataUpdateDetails')}</p>
          <p className="paragraph">{t('productsAreAddedByUsers')}</p>
          <p className="sectionDetails">{t('productsAreAddedByUsersDetails')}</p>
          <p className="paragraph">{t('userResponsibility')}</p>
          <p className="sectionDetails">{t('userResponsibilityDetails')}</p>
          <p className="paragraph">{t('medicalAdvice')}</p>
          <p className="sectionDetails">{t('medicalAdviceDetails')}</p>
          <p className="paragraph">{t('reportingErrors')}</p>
          <p className="sectionDetails">{t('reportingErrorsDetails')}</p>
          <p className="paragraph">{t('appUpdates')}</p>
          <p className="sectionDetails">{t('appUpdatesDetails')}</p>
          <p className="paragraph">{t('userPrivacy')}</p>
          <p className="sectionDetails">{t('userPrivacyDetails')}</p>
          <p className="paragraph">{t('changesToTerms')}</p>
          <p className="sectionDetails">{t('changesToTermsDetails')}</p>
          <p className="paragraph">{t('scopeOfValidity')}</p>
          <p className="sectionDetails">{t('scopeOfValidityDetails')}</p>
          <p className="paragraph">{t('contact')}</p>
          <p className="sectionDetails">{t('contactDetails')}</p>
        </div>
      </div>

      {/* Footer for Terms Page */}
      <Footer></Footer>
    </div>
  );
};

export default Terms;
