import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './LandingPage.css'; // Add some styles for the landing page
import backgroundImage from './cukrzyca.jpg'; // Add background image
import diabCalcImage from './logo.jpg'; // Add DiabCalc image
import logoImage from './logo.jpg'; // Add logo image
import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import InfoLanding from "./LandingInfo";
import NavBar from "./NavBar";
import Footer from "./Footer";

import { Capacitor } from '@capacitor/core';

const LandingPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [language, setLanguage] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        // Simulated fetching and setting language based on IP address
        const countryCode = 'pl'; // Hardcoded for this example
        const newLanguage = countryCode === 'pl' ? 'pl' : 'en';
        i18n.changeLanguage(newLanguage);
        setLanguage(newLanguage);
      })
      .catch(error => {
        console.error('Error fetching IP address:', error);
      });

    const isPWA = () => {
      const isInStandaloneMode = () => ('standalone' in window.navigator) && window.navigator.standalone;
      const hasServiceWorker = () => 'serviceWorker' in navigator && navigator.serviceWorker.controller;

      return isInStandaloneMode() || hasServiceWorker();
    };
    if (Capacitor.isNativePlatform()) {
      navigate('/app')
    }
    if (isPWA()) {
      navigate('/app');
    }
  }, [navigate]);

  const handleOpenWebVersion = () => {
    navigate('/app');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="landing-page" style={{ backgroundImage: `url(${backgroundImage})` }}>
     <NavBar></NavBar>
      <InfoLanding t={t} handleOpenWebVersion={handleOpenWebVersion} />
     <Footer></Footer>
    </div>
  );
};

export default LandingPage;
