import React, { useState } from 'react';
import './CalcView.css';
import { toast  } from 'react-toastify';
import axios from 'axios';
import { useUser } from './UserContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CalcView = () => {
  const [carbs, setCarbs] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [productName, setProductName] = useState('');
  const { user } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const calculateCU = (carbohydrates) => {
    return carbohydrates ? parseFloat((carbohydrates / 10).toFixed(1)) : null;
  };

  const generateRandomNumber = () => Math.floor(Math.random() * 1000000);

  const addToMeal = async () => {
    if (!user) {
      toast.error(t('mustBeLoggedIn'));
      navigate('/app/login');
      return;
    }
    
    try {
      const productId = generateRandomNumber();
      const token = user.token; // Pobierz token JWT
      
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      const response = await axios.post(
        'https://api.diabcalc.com/addProductToMeal',
        {
          userId: user.userId,
          productId: productId,
          productJson: JSON.stringify({
            product_name: productName,
            nutriments: {
              carbohydrates_100g: carbs,
            },
          }),
        },
        {
          headers: headers
        }
      );

      const { success, message } = response.data;

      if (success) {
        toast.success(t('addedToMeal', { productName }));
      } else {
        toast.error(`${t('errorOccurred')}: ${message}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(t('errorSaving'));
    }

    setShowPopup(false);
    setProductName('');
    setCarbs('');
  };



  return (
    <div className='panel'>
      <div className='info'>
        <h1>{t('calculateExchange')}</h1>
        <input
          type="number"
          value={carbs}
          onChange={(e) => setCarbs(e.target.value)}
          placeholder={t('enterCarbs')}
        />
        <p>{t('exchangePer100g')}:</p>
        <h1>{calculateCU(carbs)}</h1>
        <button className='btnaddproduct' onClick={() => setShowPopup(true)}>
          {t('savecalc')}
        </button>
        {showPopup && (
          <div className="popup">
            <h2>{t('enterProductInfo')}</h2>
            <input
              type="text"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              placeholder={t('productName')}
            />
            <button className='btnaddproduct' onClick={addToMeal}>
              {t('saveProduct')}
            </button>
            <button className='btnaddproduct' disabled>
              {t('addProduct')}
            </button>
          </div>
        )}
      </div>
   
    </div>
  );
};

export default CalcView;
