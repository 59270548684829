import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './LandingPage.css'; // Add some styles for the landing page
import NavBar from "./NavBar";
import { useTranslation } from 'react-i18next';
import IonIcon from '@reacticons/ionicons';
import Footer from './Footer';

const Contact = () => {
  const navigate = useNavigate();
const {t} = useTranslation();
  const handleOpenWebVersion = () => {
    navigate('/app');
  };

  return (
    <div className="landing-page about">
      <NavBar></NavBar>
      {/* Zawartość strony "About Us" */}
      <div className="landinginfo">
        <div className='contact'>
     <h1>{t('contact')}</h1>  
      <IonIcon name="mail-outline" size={50} />
     <p>{t('contactdetails')}</p>
     <p>{t('visitsm')}</p>
  </div>
</div>
      

<Footer></Footer>
    </div>
  );
};

export default Contact;
