import React, { useState, useEffect } from 'react';
import { toast  } from 'react-toastify';
import axios from 'axios';
import { useUser } from './UserContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AddProduct = () => {
  const [name, setName] = useState('');
  const [barcode, setBarcode] = useState('');
  const [energyPer100, setEnergyPer100] = useState('');
  const [fatPer100, setFatPer100] = useState('');
  const [carbsPer100, setCarbsPer100] = useState('');
  const [fiberPer100, setFiberPer100] = useState('');
  const [proteinPer100, setProteinPer100] = useState('');
  const [portion, setPortion] = useState('');
  const [brand, setBrand] = useState('');
  const [category, setCategory] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [isGlutenFree, setIsGlutenFree] = useState(false);
  const { user } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!user) {
        toast.error(t('notLoggedRedirect'));
        navigate('/app/login');
        return;
      }

      try {
        const token = user.token; // Get JWT token
        const headers = {
          'Authorization': `Bearer ${user.token}`,
          'Content-Type': 'application/json'
        };
        const url = `https://api.diabcalc.com/checkAdmin/${user.userId}`;

        const response = await axios.get(url, {
          headers: headers,
          mode: 'cors'
        });

        if (response.data.isAdmin) {
          setIsAdmin(true); // Set isAdmin flag to true if user is admin
        } else {
          toast.error(t('notAdminRedirect')); // Display error message if user is not admin
          navigate('/app/login'); // Redirect user to login page
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error(t('errorOccurred'));
        navigate('/app/login');
      } finally {
        setLoading(false); // Set loading to false after admin check is complete
      }
    };

    checkAdminStatus();
  }, [user, navigate, t]);

  const handleAddProduct = async () => {
    try {
      const token = user.token; // Get JWT token
      const headers = {
        'Authorization': `Bearer ${user.token}`,
        'Content-Type': 'application/json'
      };

      const response = await axios.post('https://api.diabcalc.com/addProduct', {
        name,
        barcode,
        energy_per100: energyPer100,
        fat_per100: fatPer100,
        carbohydrates_per100: carbsPer100,
        fiber_per100: fiberPer100,
        protein_per100: proteinPer100,
        portion,
        brand,
        category,
        image_url: imageUrl,
        glutenFree: isGlutenFree,
      }, {
        headers: headers
      });

      const { success, message, product } = response.data;

      if (success) {
        toast.success(`${t('addedNewProduct')}: ${product.name} ${t('toDatabase')}`);
        clearForm(); // Clear the form after adding product successfully
      } else {
        toast.error(`${t('errorOccurred')}: ${message}`);
      }
    } catch (error) {
      console.error('Error adding product:', error);
      toast.error(t('errorSaving'));
    }
  };

  const clearForm = () => {
    setName('');
    setBarcode('');
    setEnergyPer100('');
    setFatPer100('');
    setCarbsPer100('');
    setFiberPer100('');
    setProteinPer100('');
    setPortion('');
    setBrand('');
    setCategory('');
    setImageUrl('');
    setIsGlutenFree(false);
  };

  if (loading) {
    return <div>{t('loading')}</div>;
  }

  if (!isAdmin) {
    return <div>{t('notAuthorized')}</div>;
  }

  return (
    <div className='panel'>
      <div className='info'>
        <h1>{t('addNewProduct')}</h1>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder={t('productName')} />
        <input type="text" value={barcode} onChange={(e) => setBarcode(e.target.value)} placeholder={t('barcode')} />
        <input type="text" value={energyPer100} onChange={(e) => setEnergyPer100(e.target.value)} placeholder={t('energyPer100g')} />
        <input type="text" value={fatPer100} onChange={(e) => setFatPer100(e.target.value)} placeholder={t('fatPer100g')} />
        <input type="text" value={carbsPer100} onChange={(e) => setCarbsPer100(e.target.value)} placeholder={t('carbsPer100g')} />
        <input type="text" value={fiberPer100} onChange={(e) => setFiberPer100(e.target.value)} placeholder={t('fiberPer100g')} />
        <input type="text" value={proteinPer100} onChange={(e) => setProteinPer100(e.target.value)} placeholder={t('proteinPer100g')} />
        <input type="text" value={portion} onChange={(e) => setPortion(e.target.value)} placeholder={t('portionSize')} />
        <input type="text" value={brand} onChange={(e) => setBrand(e.target.value)} placeholder={t('brand')} />
        <input type="text" value={category} onChange={(e) => setCategory(e.target.value)} placeholder={t('category')} />
        <input type="text" value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} placeholder={t('imageUrl')} />
        <label>
          {t('glutenFree')}:
          <input
            type="checkbox"
            checked={isGlutenFree}
            onChange={(e) => setIsGlutenFree(e.target.checked)}
          />
        </label>
        <button className='btnaddproduct' onClick={handleAddProduct}>
          {t('addProduct')}
        </button>
      </div>

    </div>
  );
};

export default AddProduct;
