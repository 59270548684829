import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Settings.css';
import { toast } from 'react-toastify';
import { useUser } from './UserContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';

const Settings = () => {
  const { user, logout } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  // Funkcja do zapisywania języka w local storage
  const setStoredLanguage = (lang) => {
    localStorage.setItem('language', lang);
  };

  // Funkcja do odczytywania języka z local storage
  const getStoredLanguage = () => {
    return localStorage.getItem('language');
  };

  const [language, setLanguage] = useState(() => {
    const storedLanguage = getStoredLanguage();
    return storedLanguage || i18n.language || navigator.language.split('-')[0];
  });

  const [darkMode, setDarkMode] = useState('system');

  useEffect(() => {
    // Ustaw język aplikacji
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    // Sprawdź zapisane preferencje użytkownika
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setDarkMode(savedMode);
    } else {
      setDarkMode('system');
    }
  }, []);

  const applyTheme = (mode) => {
    if (mode === 'dark') {
      document.body.classList.add('dark-mode');
    } else if (mode === 'light') {
      document.body.classList.remove('dark-mode');
    } else if (mode === 'system') {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      if (prefersDark) {
        document.body.classList.add('dark-mode');
      } else {
        document.body.classList.remove('dark-mode');
      }
    }
  };
  
  useEffect(() => {
    applyTheme(darkMode);
    if (darkMode !== 'system') {
      localStorage.setItem('themeMode', darkMode);
    }
  }, [darkMode]);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
    setStoredLanguage(lang);
    toast.success(t('languageChanged'));
  };

  const changeMode = (mode) => {
    setDarkMode(mode);
    applyTheme(mode);
    if (mode !== 'system') {
      localStorage.setItem('themeMode', mode);
      toast.success(t('modeChanged'));
    } else {
      localStorage.removeItem('themeMode');
    }
  };

  return (
    <div className="panel">
      <div className="infosettings">
        <h1>{t('settings')}</h1>
        <h2>{t('appearance')}</h2>
        <p>{t('changeLanguage')}</p>
        <select value={language} onChange={(e) => changeLanguage(e.target.value)}>
          <option value="en">English</option>
          <option value="pl">Polski</option>
        </select>

        <p>{t('defaultTheme')}</p>
        <select value={darkMode} onChange={(e) => changeMode(e.target.value)}>
          <option value="light">{t('lightMode')}</option>
          <option value="dark">{t('darkMode')}</option>
          <option value="system">{t('systemMode')}</option>
        </select>

        {user ? (
          <div>
            <h2>{t('user')}</h2>
            <p>{t('logoutText')}</p>
            <button className="buttonlogout" onClick={logout}>
              {t('logout')}
            </button>
          </div>
        ) : null}

        <hr />
        <p>{t('otherLinks')}</p>
        <Link to="/policy">
          <p>{t('privacyPolicyLink')}</p>
        </Link>
        <Link to="/terms">
          <p>{t('termsLink')}</p>
        </Link>
      </div>
    </div>
  );
};

export default Settings;
