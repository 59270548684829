import React, { useState, useEffect } from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import { useUser } from './UserContext';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import IonIcon from '@reacticons/ionicons';
import { toast } from 'react-toastify';
import axios from 'axios';

import scannerimg from './scanner.png';
import magnifierimg from './magnifier.png';
import addpng from './add.png';
import mealimg from './meal.png';
import videotutorialios from './ios_howto.mp4';
import videotutorialandroid from './android_howto.mp4';

const Home = () => {
  const [showInstructions, setShowInstructions] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const { user } = useUser();
  const { t } = useTranslation();
  const IS_ENV_DEVELOPMENT = window.location.hostname.includes('localhost');
  let apiUrl = 'https://api.diabcalc.com/';

  if (IS_ENV_DEVELOPMENT) {
    apiUrl = 'http://localhost:3001/';
  }

  useEffect(() => {
    checkAdminStatus();
  }, [user]);

  const getToken = () => {
    // Implementacja funkcji do pobierania tokena JWT, np. z localStorage
    return localStorage.getItem('accessToken');
  };

  const checkAdminStatus = async () => {
    if (!user) {
      setIsAdmin(false);
      return;
    }

    try {
      const userId = user.userId;
   

      const token = user.token;
      const headers = {
        'Authorization': `Bearer ${user.token}`,
        'Content-Type': 'application/json'
      };

      const response = await axios.get(`${apiUrl}checkAdmin/${userId}`, {
        headers: headers
      });

      const data = response.data;

      if (response.status === 200) {
        setIsAdmin(data.isAdmin);
        sessionStorage.setItem(`isAdmin_${userId}`, data.isAdmin.toString());
      } else {
        console.error(`Error checking admin status: ${data.message}`);
      }
    } catch (error) {
      console.error(`Error checking admin status: ${error.message}`);
    }
  };

  const handleButtonClick = () => {
    setShowInstructions(true);
  };

  const handleCloseModal = () => {
    setShowInstructions(false);
  };

  return (
    <div className='panel'>
      <div className='infohome'>
    

        <h1>{t('welcome')}</h1>

        <p>
          <b>{t('quicksteps')}</b>
        </p>
<div className="button-container">
        <div className='add-section'>
          <button className='btn' onClick={handleButtonClick}>
            <IonIcon name='home' className='home' size={50} />
            <p>
              <b>{t('addinfo')}</b>
            </p>
          </button>
        </div>

        <Link to='/app/scanner'>
          <div className='barcode-section'>
            <IonIcon name='scan' className='scan' size={50} />
            <p>
              <b>{t('scaninfo')}</b>
            </p>
          </div>
        </Link>

        <Link to='/app/search'>
          <div className='search-section'>
            <IonIcon name='search' className='searchbtn' size={50} />
            <p>
              <b>{t('searchinfo')}</b>
            </p>
          </div>
        </Link>

        <Link to='/app'>
          <div className='meal-section'>
            <IonIcon name='restaurant' className='meal' size={50} />
            <p>
              <b>{t('mealsinfo')}</b>
            </p>
          </div>
        </Link>
        </div>
        
      <div className='admin-options'>
    {isAdmin && (
          <div className='admin-options'>
            <Link to='/app/add-product'>
              <p>   <IonIcon name='add-circle-outline'  />{t('addproductinfo')}</p>
            </Link>
            <Link to='/app/userslist'>
              <p> <IonIcon name='people-outline'  />{t('manageUsers')}</p>
            </Link>
          </div>
        )}
</div> 
 </div>
      {showInstructions && (
        <div className='modal'>
          <span className='close' onClick={handleCloseModal}>
            &times;
          </span>
          <div className='modalcontent'>
            <h2>{t('instructions')}</h2>
            <div className='item'>
              <p>IOS</p>
              <ReactPlayer
                url={videotutorialios}
                controls={true}
                width='50%'
                height='50%'
                muted={true}
                playing={false}
              />
            </div>
            <div className='item'>
              <p>Android</p>
              <ReactPlayer
                url={videotutorialandroid}
                controls={true}
                width='50%'
                height='50%'
                muted={true}
                playing={false}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
