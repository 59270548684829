// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './languages/en.json';
import plTranslation from './languages/pl.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation,
    },
    pl: {
      translation: plTranslation,
    },
  },
  lng: 'en', // Ustaw domyślny język
  fallbackLng: 'en', // Ustaw język zapasowy
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
