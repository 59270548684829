import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './LandingPage.css';
import logoImage from './logo.jpg';
import { useTranslation } from 'react-i18next';
import IonIcon from '@reacticons/ionicons';

const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="top-bar">
      <Link to="/" className="logo-container">
        <img src={logoImage} alt="DiabCalc Logo" className="logolanding" />
        <span className="diabcalc-title">DiabCalc</span>
      </Link>
      <div className={`nav-links ${menuOpen ? 'open' : ''}`}>
        <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>{t('onas')}</Link>
        <Link to="/contact" className={location.pathname === '/contact' ? 'active' : ''}>{t('contact')}</Link>
       
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        &#9776;
      </div>
    </div>
  );
};

export default NavBar;
