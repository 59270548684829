import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios'; // Import Axios
import { useUser } from './UserContext';

const InternetStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [connectionType, setConnectionType] = useState(navigator.connection ? navigator.connection.effectiveType : 'unknown');
  const [showStatus, setShowStatus] = useState(true); // State to control the visibility of the status message
  const [isVerified, setIsVerified] = useState(false); // State to track email verification status
  const { user } = useUser();

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    const updateConnectionStatus = () => {
      if (navigator.connection) {
        setConnectionType(navigator.connection.effectiveType);
      }
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
    if (navigator.connection) {
      navigator.connection.addEventListener('change', updateConnectionStatus);
    }

    // Cleanup functions
    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
      if (navigator.connection) {
        navigator.connection.removeEventListener('change', updateConnectionStatus);
      }
    };
  }, []);

  useEffect(() => {
    // Initial check of verification status if user exists
    if (user) {
      checkVerification();
    } else {
      setIsVerified(true); // Assume verified if user is not present
    }

    // Set interval to check verification status every 10 seconds if user exists
    let interval;
    if (user) {
      interval = setInterval(() => {
        checkVerification();
      }, 10000);
    }

    return () => {
      if (interval) {
        clearInterval(interval); // Cleanup interval on component unmount or user change
      }
    };
  }, [user]);

  const checkVerification = async () => {
    if (!user) {
      setIsVerified(false); // Jeśli użytkownik nie jest zalogowany, ustawiamy isVerified na false
      return;
    }
  
    try {
      const userId = user.userId;
      const token = user.token;
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
  
      const response = await axios.get(`https://api.diabcalc.com/checkVerify/${userId}`, {
        headers: headers
      });
  
      const data = response.data;
  
      if (response.status === 200) {
        setIsVerified(data.isVerify);
      
      } else {
        console.error(`Error checking verification status: ${data.message}`);
        setIsVerified(false); // Ustawienie isVerified na false w przypadku niepowodzenia
      }
    } catch (error) {
      console.error(`Error checking verification status: ${error.message}`);
      setIsVerified(false); // Ustawienie isVerified na false w przypadku błędu
    }
  };
  
  const isConnectionWeak = ['2g', 'slow-2g'].includes(connectionType);

  const handleClose = () => {
    setShowStatus(false);
  };

  return (
    <>
      {showStatus && (
        <div className="internet-status">
          <p><strong> Ta strona to wersja beta aplikacji DiabCalc. Nie działa narazie tu łączenie z serwerem. Jeśli chcesz użyć aplikacji przejdź na  </strong><a href='https://diabcalc.com/'>diabcalc.com</a></p>
          <button className="close-button" onClick={handleClose}>
            X
          </button>
        </div>
      )}
      {!isOnline && (
        <div className="internet-status">
          <p><strong>Jesteś obecnie offline. Niektóre funkcje mogą być niedostępne.</strong></p>
        </div>
      )}
      {isConnectionWeak && isOnline && (
        <div className="internet-status weak-connection">
          <p><strong>Twoje połączenie jest słabe. Możesz doświadczać opóźnień.</strong></p>
        </div>
      )}
      {!isVerified && (
        <div className="internet-status">
          <p><strong>Twój email nie jest zweryfikowany. Proszę zweryfikuj swój email, aby uzyskać pełen dostęp.</strong></p>
        </div>
      )}
    </>
  );
};

export default InternetStatus;
