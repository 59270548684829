import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from './UserContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IonIcon from "@reacticons/ionicons";
import './SearchView.css';

const SearchView = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [showSearch, setShowSearch] = useState(true);

  useEffect(() => {
    if (error) {
      toast.error(`${t('errorOccurred')}: ${error}`);
      setError(null);
    }
  }, [error, t]);

  const searchProducts = async () => {
    try {
      const response = await axios.get('https://diabcalcapi.koder1.workers.dev/search', {
        params: { query: encodeURIComponent(query) },
        withCredentials: true,
      });

      const { success, products, message } = response.data;

      if (success) {
        setProducts(products);
        setShowSearch(false);
      } else {
        setError(message);
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
    }
  };

  const calculateCU = (carbohydrates) => {
    return carbohydrates ? parseFloat((carbohydrates / 10).toFixed(1)) : null;
  };

  const calculatePerServing = (per100g, servingSize) => {
    const servingSizeInGrams = parseFloat(servingSize);
    if (!isNaN(servingSizeInGrams) && per100g !== null) {
      return (per100g * servingSizeInGrams) / 100;
    }
    return null;
  };

  const formatNumber = (number) => {
    if (typeof number === 'number' && isFinite(number)) {
      return number.toFixed(1);
    } else {
      return t('noInformation');
    }
  };

  const generateRandomNumber = () => Math.floor(Math.random() * 1000000);

  const addToMeal = async (user, product) => {
    if (!user) {
      toast.error(t('mustBeLoggedInToAddProduct'));
      navigate('/app/login');
      return;
    }

    try {
      const productId = generateRandomNumber();

      const response = await axios.post(
        'https://api.diabcalc.com/addProductToMeal',
        {
          userId: user.userId,
          productId: productId,
          productJson: JSON.stringify({
            product_name: product.product_name || product.name,
            barcode: product.barcode || '',
            nutriments: {
              carbohydrates_100g: product.carbohydrates_per100 || product.nutriments?.carbohydrates_100g || product.nutriments?.carbohydrates || 0,
              proteins_100g: product.protein_per100 || product.nutriments?.proteins_100g || 0,
              fats_100g: product.fat_per100 || product.nutriments?.fats_100g || 0,
            },
          }),
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          withCredentials: true,
        }
      );

      const { success, message } = response.data;

      if (success) {
        toast.success(`${t('saved')} ${product.product_name || product.name} ${t('saved2')}`);
      } else {
        toast.error(`${t('errorOccurred')}: ${message}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(t('errorSaving'));
    }
  };

  return (
    <div className='panel'>
      <div className='product-search'>
        {showSearch ? (
          <div className='search'>
            <input 
              type="text" 
              value={query} 
              onChange={(e) => setQuery(e.target.value)} 
              onKeyDown={(e) => { if (e.key === 'Enter') searchProducts() }} 
            />
            <button onClick={searchProducts}>{t('search')}</button>
          </div>
        ) : (
          <button onClick={() => setShowSearch(true)}>
            <IonIcon size='50px' name="search-outline" />
          </button>
        )}
        <div className='product-list'>
          {products.length === 0 ? (
            <div className='notfound'>
              <p>{t('noProductsFound')}</p>
            </div>
          ) : (
            products.map((product, index) => (
              <div key={index} className='product-item'>
                <h2>{product.product_name}</h2>
                <img src={product.image_url} alt={product.product_name} />
                <button onClick={() => addToMeal(user, product)}>
                  {t('save')}
                </button>
                <button className='btnaddproduct' disabled>
              {t('addProduct')}
            </button>
                {product.is_gluten_free !== undefined ? (
                  product.is_gluten_free ? 
                  <p>{t('glutenFree')}</p> : 
                  <p>{t('containsGluten')}</p>
                ) : (
                  <p></p>
                )}
                <table>
                  <thead>
                    <tr>
                      <th>{t('nutritionalValue')}</th>
                      <th>{t('per100g')}</th>
                      <th>{`${t('perServing')} ${product.serving_size}`}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t('carbohydrates')}</td>
                      <td>{formatNumber(product.nutriments.carbohydrates)}</td>
                      <td>{formatNumber(calculatePerServing(product.nutriments.carbohydrates, product.serving_size))}</td>
                    </tr>
                    <tr>
                      <td>{t('protein')}</td>
                      <td>{formatNumber(product.nutriments.proteins)}</td>
                      <td>{formatNumber(calculatePerServing(product.nutriments.proteins, product.serving_size))}</td>
                    </tr>
                    <tr>
                      <td>{t('fat')}</td>
                      <td>{formatNumber(product.nutriments.fat)}</td>
                      <td>{formatNumber(calculatePerServing(product.nutriments.fat, product.serving_size))}</td>
                    </tr>
                    <tr>
                      <td>{t('exchange')}</td>
                      <td>{calculateCU(product.nutriments.carbohydrates)}</td>
                      <td>{calculateCU(calculatePerServing(product.nutriments.carbohydrates, product.serving_size))}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchView;
