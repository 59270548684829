import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './LandingPage.css'; // Add some styles for the landing page
import NavBar from "./NavBar";
import { useTranslation } from 'react-i18next';
import KacperImg from './files/Kacper.jpg';
import Footer from './Footer';

const AboutUsPage = () => {
  const navigate = useNavigate();
const {t} = useTranslation();
  const handleOpenWebVersion = () => {
    navigate('/app');
  };

  return (
    <div className="landing-page about">
      <NavBar></NavBar>
      {/* Zawartość strony "About Us" */}
      <div className="landinginfo"> 
      <h1>{t('ourteam')}</h1>
        <div className='member'>
       
        <h2>Kacper Sikora</h2>
<img className="imgks" src={KacperImg}></img>
<p>{t('ksopis')}</p>
</div>
<div className='member'>
      
      

</div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default AboutUsPage;
