import React from 'react';
import PropTypes from 'prop-types';
import ScannerImg from './files/Scanner.png';
import MealImg from './files/Meal.png';
import SearchImg from './files/Search.png';
import StartImg from './files/Start.png';


import diabCalcImage from './logo.jpg'; // Zaktualizuj ścieżkę do obrazu
import './LandingPage.css'; // Upewnij się, że ścieżka do CSS jest poprawna
import IonIcon from "@reacticons/ionicons";
const LandingInfo = ({ t, handleOpenWebVersion }) => {
  return (
    <div className='landinginfo'>
      <header className="landing-header">
        <img src={diabCalcImage} className="diabcalc-image" alt="DiabCalc" />
        <h1 className="landing-title">{t('welcomedc')}</h1>
        <p className="landing-description">{t('diabcalcinfo')}</p>
        <hr />
        <h1 className="downloadtitle">{t('pobierz')}</h1>
        <button className="open-web-btn" onClick={handleOpenWebVersion}>
          <IonIcon name="globe-outline" className='home' size={50} />
          {t('openweb')}<br />
          {t('devicesweb')}
        </button>
      </header>
      <hr/>
      <section className="features-section">
        <h2 className="section-title">{t('Features')}</h2>
        <p className="features-description">{t('odkryj')}</p>
        <div className='feature'>
  <h2 className="scannertext">{t('scan')}</h2>
  <img src={ScannerImg} className='img'></img>
  
  </div>
  <div className='feature'>
  <h2 className="mealtext">{t('mealtext')}</h2>
  <img src={MealImg} className='img'></img>
  
  </div>
  <div className='feature'>
  <h2 className="searchtext">{t('searchtext')}</h2>
  <img src={SearchImg} className='img'></img>
  
  </div>
  <div className='feature'>
  <h2 className="starttext">{t('start')}</h2>
  <img src={StartImg} className='img'></img>
  
  </div>
      </section>
    </div>
  );
};

LandingInfo.propTypes = {
  t: PropTypes.func.isRequired,
  handleOpenWebVersion: PropTypes.func.isRequired,
};

export default LandingInfo;
