import {React,useEffect,useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import "./LandingPage.css";
import i18n from './i18n';
import IonIcon from "@reacticons/ionicons";

const Footer = () => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState("pl");

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  
  };

  return (
    <footer className="landing-footer">
      <div className="footer-text">
        {t('footerinfo')}
        <Link className="zwolnieni" to="https://zwolnienizteorii.pl/">
          {t('footerzwolnieni')}
        </Link>
        <br></br>
        {t('otherLinks')}
          <Link className="link" to="/policy"  ><p>{t('privacyPolicyLink')}</p></Link>
          <Link className="link" to="/terms"  ><p>{t('termsLink')}</p></Link>
          <select value={language} className='selectlanguage' onChange={(e) => changeLanguage(e.target.value)}>
          <option value="en">English</option>
          <option value="pl">Polish</option>
           </select>
           <div className="social-media">
        <Link to="https://www.instagram.com/diabcalcapp"  className="social-link">
        <IonIcon name="logo-instagram"   size={50} />
        </Link>
        <Link to="https://www.youtube.com/@DiabCalcApp"  className="social-link">
        <IonIcon name="logo-youtube"  size={50} />
        </Link>
        <Link to="https://www.tiktok.com/@diabcalc"  className="social-link">
        <IonIcon name="logo-tiktok"   size={50} />
        </Link>
        <Link to="https://www.facebook.com/profile.php?id=61557310356369"  className="social-link">
        <IonIcon name="logo-facebook"   size={50} />
        </Link>
        <Link to="https://www.linkedin.com/company/diabcalc"  className="social-link">
        <IonIcon name="logo-linkedin"   size={50} />
        </Link>
      </div>
      </div>
    </footer>
  );
};

export default Footer;
