import React from 'react';
import './Policy.css';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom for external links
import { useTranslation } from 'react-i18next';
import NavBar
 from './NavBar';
 import Footer from './Footer';
 
const Policy = () => {
  const { t } = useTranslation();

  return (
    <div className='landing-page policy'>
      {/* Navbar for Policy Page */}
      <NavBar />

      {/* Content of Policy Page */}
      <div className='landinginfo'>
        <h1>{t('privacyPolicy')}</h1>
        <hr />

        <p className='zakladkip'>{t('appUsageRules')}</p>
        
        <ol>
          <li>
            <strong>{t('dataTypes')}:</strong> {t('appCollectsMealData')}
          </li>
          <li>
            <strong>{t('processingPurpose')}:</strong> {t('mealDataProcessedFor')}
          </li>
          <li>
            <strong>{t('legalBasis')}:</strong> {t('dataProcessedBasedOnUserConsent')}
          </li>
          <li>
            <strong>{t('dataSharing')}:</strong> {t('mealDataNotSharedWithoutConsent')}
          </li>
          <li>
            <strong>{t('cookies')}:</strong> {t('appUsesCookies')}
          </li>
          <li>
            <strong>{t('dataSecurity')}:</strong> {t('appropriateMeasuresTakenToProtectMealData')}
          </li>
          <li>
            <strong>{t('userRights')}:</strong> {t('usersHaveRightsToAccess')}
          </li>
          <li>
            <strong>{t('dataRetentionPeriod')}:</strong> {t('mealDataStoredForNecessaryPeriod')}
          </li>
          <li>
            <strong>{t('consent')}:</strong> {t('byUsingAppUserConsentsToPrivacyPolicy')}
          </li>
          <li>
            <strong>{t('deleteaccount')}:</strong> {t('youwanttodeleteaccount')}
          </li>
        </ol>
        <p>{t('questionsRegardingPrivacyPolicy')} <a href="mailto:koder1@interia.pl">koder1@interia.pl</a></p>
      </div>

      {/* Footer for Policy Page */}
      <Footer></Footer>
    </div>
  );
};

export default Policy;
