import React, { useState, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useUser } from './UserContext';
import './ListView.css';
import { Link ,useNavigate} from 'react-router-dom';

const ListView = () => {
  const { t } = useTranslation();
  const [meal, setMeal] = useState([]);
  const [calculatedWw, setCalculatedWw] = useState({});
  const [totalWwForCalculator, setTotalWwForCalculator] = useState(0);
  const { user } = useUser();
  const [value, setValue] = useState({});
  const navigate = useNavigate();

 const fetchData = useCallback(async () => {
    try {
      if (!user) {
        console.error('User not available. Redirecting to login...');
        navigate('/app/login');
        return;
      }

      const token = user.token;
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      const userId = user.userId;
      const response = await axios.get(`https://api.diabcalc.com/getProductsInMeal/${userId}`, {
        headers: headers
      });

      if (response.data.success) {
        setMeal(response.data.mealProducts || []);
      } else {
        console.error('Error in server response:', response.data);
      }
    } catch (error) {
      console.error('An error occurred during communication with the server:', error);
    }
  }, [user, navigate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const calculateWW = useCallback((productId, grams) => {
    if (!isNaN(grams) && grams >= 0) {
      const product = meal.find((product) => product.Id === productId);
      const nutriments = JSON.parse(product.json).nutriments;
      const carbohydrates_100g = nutriments?.carbohydrates_100g;
      const cu = calculateCU(carbohydrates_100g);
      const ww = (grams * cu) / 100;

      setCalculatedWw((prevWw) => {
        const updatedWw = { ...prevWw, [productId]: ww.toFixed(1) };
        const totalWw = Object.values(updatedWw).reduce((acc, value) => acc + (parseFloat(value) || 0), 0);
        setTotalWwForCalculator(parseFloat(totalWw.toFixed(1)));
        return updatedWw;
      });
    } else {
      setCalculatedWw((prevWw) => ({ ...prevWw, [productId]: null }));
    }
  }, [meal]);

  const calculateCU = (carbohydrates) => {
    return carbohydrates ? parseFloat((carbohydrates / 10).toFixed(1)) : null;
  };

  const calculateTotalCU_100g = () => {
    const totalCU = meal.reduce(
      (acc, product) => acc + calculateCU(JSON.parse(product.json).nutriments.carbohydrates_100g),
      0
    );
    return parseFloat(totalCU.toFixed(1));
  };

  const calculateCarbsPer100g = (product) => {
    try {
      const nutriments = JSON.parse(product.json).nutriments;

      if (nutriments && nutriments.carbohydrates_100g !== undefined) {
        return calculateCU(nutriments.carbohydrates_100g);
      } else {
        return t('noInformation');
      }
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return t('dataError');
    }
  };

  const removeFromMeal = async (productId) => {
    try {
      const token = user.token;
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      const response = await axios.delete(`https://api.diabcalc.com/removeProductFromMeal/${productId}`, {
        headers: headers
      });

      if (!response.data.success) {
        console.error(`Server error: ${response.status} - ${response.statusText}`);
        return;
      }

      const updatedMeal = meal.filter((product) => product.Id !== productId);
      setMeal(updatedMeal);

      const totalWw = Object.values(calculatedWw).reduce((acc, value) => acc + (parseFloat(value) || 0), 0);
      setTotalWwForCalculator(parseFloat(totalWw.toFixed(1)));
    } catch (error) {
      console.error('Error removing product from meal:', error);
    }
  };


  return (
    <div className='panel'>
    <div className="meal-view">
      <h2 className="meal-title">{t('ListTitle')}</h2>
      <h3>{t('inforamtion')}</h3>
      <p className="meal-total-cu">{t('totalCU')} {calculateTotalCU_100g()}</p>
      <p className="total-ww-for-calculator">{t('totalWW')} {totalWwForCalculator}</p>

      <div className="meal-products">
        {meal.map((product) => (
          <div key={product.Id} className="meal-product">
            <h3 className="product-name">{JSON.parse(product.json).product_name}</h3>
            <p className="product-cu">{t('carbsExPer100g')}: {calculateCarbsPer100g(product)}</p>

            <div className="calculator">
              <h3>{t('calculatorTitle')}</h3>
              <label>
                {t('gramInputLabel')}
                <input
                  type="number"
                  value={value[product.Id] || ''}
                  onChange={(e) => setValue({ ...value, [product.Id]: e.target.value })}
                />
              </label>
              <button onClick={() => calculateWW(product.Id, parseFloat(value[product.Id]))}>{t('calculateWWBtn')}</button>
              {calculatedWw[product.Id] !== null && (
                <p>
                  {t('calculatedWWLabel')} {calculatedWw[product.Id]}
                </p>
              )}
            </div>

            <button className="product-remove-btn" onClick={() => removeFromMeal(product.Id)}>{t('removeFromMealBtn')}</button>
          </div>
        ))}


      </div>
      <Link className="meal-add-product-btn" to="/app/search">
         {t('searchNewProductBtn')}
        </Link>
        <Link className="scannerbtn" to="/app/scanner">
        {t('scanNewProductBtn')}
        </Link>
        <Link className="meal-add-product-btn" to="/app/calc">
        {t('addNewProductBtn')}
        </Link>
    </div>
    </div>
  );
};

export default ListView;
