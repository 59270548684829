import React, { useState, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import "./AddProductUser.css";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useUser } from './UserContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AddProductUser = () => {
  const [step, setStep] = useState(1); // Aktualny krok procesu
  const [photos, setPhotos] = useState([]); // Zdjęcia produktu
  const [isPanelVisible, setPanelVisible] = useState(true);
  const [photoDesc, setPhotoDesc] = useState('');
  const [descId, setDescId] = useState(1);
  const { t } = useTranslation(); 
  const { user } = useUser();
  const navigate = useNavigate();
  const [selectedPhoto, setSelectedPhoto] = useState(null); // Wybrane zdjęcie do wyświetlenia w modalu

  useEffect(() => {  
    if (!user) {
      toast.error(t('mustBeLoggedInToAddProduct'));
      navigate('/login');
    }
  }, [user, t, navigate]);

  const webcamRef = useRef(null);
  const videoConstraints = {
    width: { min: window.innerHeight },
    height: { min: window.innerWidth },
    facingMode: 'environment', // Use 'environment' for rear camera, 'user' for front camera
  };

  const capture = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPhotos(prevPhotos => {
      const newPhotos = [...prevPhotos, imageSrc];
      if (newPhotos.length >= 3) {
        handleNextStep();
      }
      return newPhotos;
    });
    setPanelVisible(false); // Ustawiamy stan na false
    setTimeout(() => {
      setPanelVisible(true); // Po 0.5 sekundy przywracamy stan na true
    }, 500); 
  
    setDescId(prevId => prevId + 1); // Zwiększamy descId o 1
    setPhotoDesc(t(`photoDesc${descId}`)); 
  };
  

  const handleNextStep = async () => {
    setStep(prevStep => prevStep + 1);
 
  };

  const sendPhotosToServer = async () => {
    try {
     const selectedFile = photos[1];
      const formData = new FormData();
      formData.append('file', selectedFile);
formData.append('userId',user.userId);
      await axios.post('https://api.diabcalc.com/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      alert('File uploaded successfully!');
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('An error occurred while uploading the file.');
    }
  
  };
  
  
  
  

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
  };

  const handlePrevStep = () => {
    setStep(prevStep => prevStep - 1);
  };

  const handleCloseModal = () => {
    setSelectedPhoto(null);
  };

  const handleSendPhotos = async () => {
    sendPhotosToServer();
   

    // Tutaj możesz wykonać kolejne akcje, np. przekazać zdjęcia do dalszej obróbki lub zapisu
  };

  return (
    <div className='panel'>
      <div className="addPanel">
        {step === 1 && (
          <>
            <h1>{t('timeToAdd')}</h1>
            <p>{t('prepare')}</p>
            <p>{t('photosInfo')}</p>
            <button className='button' onClick={handleNextStep}>{t('next')}</button>
          </>
        )} 
      </div>
      {step === 2 && (
        <>
          <div className="addPanel Up" style={{ opacity: isPanelVisible ? 1 : 0 }}>
            <p>{photoDesc}</p>
            <button className='button' onClick={capture}>{t('takePhoto')}</button>
            <button className='button' onClick={handlePrevStep}>{t("back")}</button>
          </div>
          <Webcam
            audio={false}
            ref={webcamRef}
            videoConstraints={videoConstraints}
            screenshotFormat="image/jpeg"
            className="webcam"
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          />
        </>
      )}
      {step === 3 && (
        <>
          <div className='addPanel ThreeStep'>
            <p>{t('checkPhotos')}</p>
            <div className="preview-photos">
              {photos.map((photo, index) => (
                <img className='photo' onClick={() => handlePhotoClick(photo)} key={index} src={photo} alt={`Photo ${index + 1}`} />
              ))}
            </div>
            <button className='button' onClick={handleSendPhotos}>{t('sendPhotos')}</button>
            <button className='button' onClick={handlePrevStep}>{t('back')}</button>  
          </div>
          {/* Modal */}
          {selectedPhoto && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={handleCloseModal}>&times;</span>
                <img src={selectedPhoto} className="photosel" alt="Selected" />
              </div>
            </div>
          )}
        </>
      )}
      {step === 4 && (
        <>
          <div className='addPanel'> 
            <p>{t('adminAddProduct')}</p>
            <p>{t('thanks')}</p>
            <Link className="button" to="/">{t('gobacktoapp')}</Link>
          </div>
        </>
      )}
    </div>
  );
};

export default AddProductUser;