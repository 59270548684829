// Scanner.js

import React, { useRef, useEffect, useState } from 'react';
import Webcam from 'react-webcam';
import Quagga from 'quagga';
import axios from 'axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from './UserContext';
import { useNavigate } from 'react-router-dom';
import './Scanner.css';
import scanningSoundFile from './scansound.mp3';
//for mobile
import { Capacitor } from '@capacitor/core';
import {
  BarcodeScanner,
  BarcodeFormat,
  LensFacing,
} from '@capacitor-mlkit/barcode-scanning';
//
import { useTranslation } from 'react-i18next';

const videoConstraints = {
  width: { min: window.innerHeight },
  height: { min: window.innerWidth },
  facingMode: 'environment', // Use 'environment' for rear camera, 'user' for front camera
};


const calculateCU = (carbohydrates) => {
  return carbohydrates ? parseFloat((carbohydrates / 10).toFixed(1)) : null;
};

const calculatePerServing = (per100g, servingSize) => {
  const servingSizeInGrams = parseFloat(servingSize);
  if (!isNaN(servingSizeInGrams) && per100g !== null) {
    return (per100g * servingSizeInGrams) / 100;
  }
  return null;
};

const formatNumber = (number) => {
  return typeof number === 'number' && isFinite(number) ? number.toFixed(1) : 'Brak informacji';
};



const ProductPopup = ({ product, onClose, onAddToMealClick }) => {
  const { t } = useTranslation();

  return (
    <div className="product-popup">
      <button className="close-button" onClick={onClose}>
        X
      </button>
      <h2>{product.product_name || product.name}</h2>
      <img src={product.image_url} alt={product.product_name} />
      <button className="addbtn" onClick={onAddToMealClick}>
        {t('save')}
      </button>
      <button className='btnaddproduct' disabled>
              {t('addProduct')}
            </button>
      {product.is_gluten_free !== undefined ? 
    (product.is_gluten_free ? <p>{t('glutenFree')}</p> : <p>{t('containsGluten')}</p>) : 
    <p></p>}

      <table>
        <thead>
          <tr>
            <th>{t('nutritionalValue')}</th>
            <th>{t('per100g')}</th>
            <th>{t('perServing', { servingSize: product.serving_size || product.portion })}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t('carbohydrates')}</td>
            <td>{formatNumber(product.carbohydrates_per100 || product.nutriments?.carbohydrates_100g)}</td>
            <td>{formatNumber(calculatePerServing(product.carbohydrates_per100 || product.nutriments?.carbohydrates_100g, product.serving_size || product.portion))}</td>
          </tr>
          <tr>
            <td>{t('protein')}</td>
            <td>{formatNumber(product.protein_per100 || product.nutriments?.proteins_100g)}</td>
            <td>{formatNumber(calculatePerServing(product.protein_per100 || product.nutriments?.proteins_100g, product.serving_size || product.portion))}</td>
          </tr>
          <tr>
            <td>{t('fat')}</td>
            <td>{formatNumber(product.fat_per100 || product.nutriments?.fats_100g)}</td>
            <td>{formatNumber(calculatePerServing(product.fat_per100 || product.nutriments?.fats_100g, product.serving_size || product.portion))}</td>
          </tr>
          {/* Add other nutritional information */}
          <tr>
            <td>{t('WW')}</td>
            <td>{calculateCU(product.carbohydrates_per100 || product.nutriments?.carbohydrates_100g)}</td>
            <td>{calculateCU(calculatePerServing(product.carbohydrates_per100 || product.nutriments?.carbohydrates_100g, product.serving_size || product.portion))}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const Scanner = () => {
  const webcamRef = useRef(null);
  const [product, setProduct] = useState(null);
  const [showProductPopup, setShowProductPopup] = useState(false);
  const { t } = useTranslation();
  const [scanningSound, setScanningSound] = useState(new Audio(scanningSoundFile));
  const [isScanningEnabled, setScanningEnabled] = useState(true);
  const InitializeScanner = () => {
    Quagga.init(
      {
        inputStream: {
          type: 'LiveStream',
          target: document.querySelector('#target'),
          constraints: videoConstraints,
        },
        locator: {
          patchSize: 'medium',
          halfSample: true,
        },
        numOfWorkers: 2,
        decoder: {
          readers: [
            'code_128_reader',
            'ean_reader',
            'ean_8_reader',
            'code_39_reader',
            'code_39_vin_reader',
            'codabar_reader',
            'upc_reader',
            'upc_e_reader',
            'i2of5_reader',
            '2of5_reader',
            'code_93_reader',
          ],
        },
      },
      (err) => {
        if (err) {
          console.error(err);
          toast.error(t('scannerInitializationError'));
          return;
        }

        console.log('Initialization finished. Ready to start');

        Quagga.onDetected((result) => {
          const code = result.codeResult.code;
          handleBarcodeDetected(code);
        });

        Quagga.start();

        return () => {
          Quagga.offDetected(handleBarcodeDetected);
        };
      }
    );
  };

  const [errorMessage, setErrorMessage] = useState('');
  const handleBarcodeDetected = async (code) => {
    setErrorMessage('');
    scanningSound.play();
    try {
      if (isScanningEnabled === true) {
        const response = await axios.get(`https://diabcalcapi.koder1.workers.dev/scan?barcode=${code}`, {
          withCredentials: true, // Ensure CORS is handled
        });

        if (response.status === 200) {
          if (response.data.success) {
            setErrorMessage('');
            if (product?.name !== response.data.product?.name) {
              scanningSound.play();
              
              setProduct(response.data.product);
              setShowProductPopup(true);
              setScanningEnabled(false);
            }
          } else {
            setErrorMessage(response.data.message);
          }
        } else {
          // Handle cases where the request was successful but the product was not found
          if (response.status === 404) {
            // Check the server's response for more details
            if (response.data && response.data.message) {
              return toast.error(response.data.message, { autoClose: 5000 });
            } else {
              return toast.error(t('productNotFound'), { autoClose: 5000 });
            }
          } else {
            // Handle other non-404 errors
            toast.error(t('barcodeScanningError'));
          }
        }
      }
    } catch (error) {
      // Check if the error is a 404 response and avoid logging to the console
      if (error.response && error.response.status === 404) {
        return setErrorMessage(t('codeOrProductNotFound'));
      } else {
        // Handle other errors and avoid logging to the console
        toast.error(t('barcodeScanningError'));
      }
    }
  };

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      InitializeScanner();
    } else {
      startNativeBarcodeScanning(); // Rozpoczęcie skanowania na platformach natywnych
    }
  }, []); // Make sure to add the empty dependency array here

  const closeProduct = () => {
    setProduct(null);
    setShowProductPopup(false);
    setScanningEnabled(true);
    Quagga.start();
  };

 


const addToMeal = async (user, product, navigate,toast,t) => {
  // Check if the user is logged in
  if (!user) {
    toast.error(t('mustBeLoggedInToAddProduct'));
    navigate('/app/login');
    return;
  }

  try {
    // Generate a unique ID for the product
    const productId = generateRandomNumber();

    // Make a POST request to add the product to the meal
    const response = await axios.post(
      'https://api.diabcalc.com/addProductToMeal',
      {
        userId: user.userId,
        productId: productId,
        productJson: JSON.stringify({
          // Include relevant product details
          product_name: product.product_name || product.name,
          barcode: product.barcode || '',
          nutriments: {
            carbohydrates_100g: product.carbohydrates_per100 || product.nutriments?.carbohydrates_100g || product.nutriments?.carbohydrates || 0,
            proteins_100g: product.protein_per100 || product.nutriments?.proteins_100g || 0,
            fats_100g: product.fat_per100 || product.nutriments?.fats_100g || 0,
          },
        }),
      },
      {
        headers: {
          Authorization: `Bearer ${user.token}`, // Pass JWT as Authorization header
        },
        withCredentials: true, // Ensure proper handling of credentials
      }
    );

    const { success, message } = response.data;

    if (success) {
      toast.success(`${t('saved')} ${product.product_name || product.name} ${t('saved2')}`);
    } else {
      toast.error(`${t('errorOccurred')}: ${message}`);
    }
  } catch (error) {
    console.error(error);
    toast.error(t('errorSaving'));
  }
};



  
  const startNativeBarcodeScanning = async () => {
    try {
      await BarcodeScanner.prepare(); // Przygotowanie skanera
  
      // Wywołanie funkcji do ciągłego skanowania kodów kreskowych
      BarcodeScanner.scanContinuous({
        formats: [ // Wszystkie możliwe typy kodów kreskowych
          BarcodeFormat.QR_CODE,
          BarcodeFormat.AZTEC,
          BarcodeFormat.CODABAR,
          BarcodeFormat.CODE_39,
          BarcodeFormat.CODE_93,
          BarcodeFormat.CODE_128,
          BarcodeFormat.DATA_MATRIX,
          BarcodeFormat.EAN_8,
          BarcodeFormat.EAN_13,
          BarcodeFormat.ITF,
          BarcodeFormat.PDF_417,
          BarcodeFormat.UPC_A,
          BarcodeFormat.UPC_E,
        ],
        lensFacing: LensFacing.BACK, // Użycie tylnej kamery
      }, handleBarcodeDetected); // Przekazanie funkcji obsługi wykrytego kodu
  
    } catch (error) {
      console.error('Error starting continuous barcode scanning:', error);
      toast.error(t('barcodeScanningError')); // Wyświetlenie komunikatu błędu
    }
  };
  
  
  
  
  const generateRandomNumber = () => Math.floor(Math.random() * 1000000);
  const { user } = useUser();
  const navigate = useNavigate();
if(!Capacitor.isNativePlatform()){
  return (
    <div className="panel">

      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      
        <Webcam
          id="target"
          className='target'
          ref={webcamRef}
          audio={false}
          videoConstraints={videoConstraints}
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        />
        
        {showProductPopup && (
          <ProductPopup product={product} onClose={closeProduct} onAddToMealClick={() => addToMeal(user, product, navigate,toast,t)} />
        )}
  {errorMessage && <div className='errormessage'>{errorMessage}</div>}
     
      </div>
    
    </div>
  );
}
else {
  // Render natywny skanowania kodów kreskowych dla platform mobilnych
  return (
    <div className="panel">
  
      {/* Możesz dodać dodatkowe elementy interfejsu lub komponenty */}
      {showProductPopup && (
        <ProductPopup product={product} onClose={closeProduct} onAddToMealClick={() => addToMeal(user, product, navigate)} />
      )}
      {errorMessage && <div className='errormessage'>{errorMessage}</div>}
     
    </div>
  );
}
}
export default Scanner;