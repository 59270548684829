import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import './RegisterPanel.css'; // Stworzony plik CSS dla tego komponentu

const RegisterPanel = () => {
  const { t } = useTranslation(); // Use the useTranslation hook
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const handleRegister = async () => {
    if (!username || !password || !confirmPassword) {
      toast.error(t('register1.missingFields'));
      return;
    }

    if (password !== confirmPassword) {
      toast.error(t('register1.passwordMismatch'));
      return;
    }

    try {
      const response = await axios.post('https://api.diabcalc.com/register', { username, password },{
        withCredentials: true, // Ensure CORS is handled
      });

      if (response.data.success) {
        toast.success(t('register1.success'));
        navigate('/app/login');
      } else {
        toast.error(t('register1.failure'));
      }
    } catch (error) {
      console.error(error.response.data);
      toast.error(t('register1.error'));
    }
  };

  return (
    <div className='panel'>
      <div className='register'>
        <h1>{t('register1.title')}</h1>
        <hr />

        <label>{t('register1.email')} <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} /></label>
        <label>{t('register1.password')} <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} /></label>
        <label>{t('register1.confirmPassword')} <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} /></label>
        <button onClick={handleRegister}>{t('register1.registerButton')}</button>
        <p>{t('register1.acceptTerms')} <Link className='change' to="/terms">{t('register1.terms')}</Link> {t('register1.and')} <Link className='change' to="/policy">{t('register1.privacyPolicy')}</Link></p>
        <p>{t('register1.alreadyHaveAccount')} <Link className='change' to="/app/login">{t('register1.login')}</Link>.</p>
      </div>
      
    </div>
  );
};

export default RegisterPanel;
